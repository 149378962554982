import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bars3Icon, PhoneIcon } from '@heroicons/react/24/solid'


const Navbar = () => {
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-2">
        <div className="flex justify-between items-center py-2">
          {/* Logo */}
          <div className="flex items-center space-x-4 logo text-primary">
          GENWIN
            {/* <img src="your-logo.png" alt="Logo" className="h-8 w-auto" /> */}
          </div>

          {/* Hamburger Icon for Mobile */}
          <button
            className="lg:hidden text-gray-700 hover:text-primary"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <Bars3Icon className="size-6 text-primary" />
          </button>

          {/* Desktop Menu */}
          <ul className="hidden lg:flex space-x-6">
            <li className={`relative group ${isActive('/') ? 'border-b-2 border-primary' : ''}`}>
              <Link
                to="/"
                className="text-18 text-gray-700 hover:text-primary transition duration-300"
                onClick={() => setIsDropdownOpen(false)}
              >
                Home
              </Link>
            </li>
            <li className={`relative group ${isActive('/about') ? 'border-b-2 border-primary' : ''}`}>
              <Link
                to="/about"
                className="text-18 text-gray-700 hover:text-primary transition duration-300"
                onClick={() => setIsDropdownOpen(false)}
              >
                About
              </Link>
            </li>
            <li className={`relative group ${isActive('/products') ? 'border-b-2 border-primary' : ''}`}>
              <Link
                to="/products"
                className="text-18 text-gray-700 hover:text-primary transition duration-300"
                onClick={() => setIsDropdownOpen(false)}
              >
                Products
              </Link>
            </li>
            {/* <li className="relative group">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className={`text-18 text-gray-700 hover:text-primary transition duration-300 ${
                  isActive('/products') ? 'border-b-2 border-primary' : ''
                }`}
              >
                Products
              </button>
              <ul
                className={`absolute left-0 mt-2 bg-white shadow-lg rounded-md w-40 transition duration-300 ${
                  isDropdownOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
                }`}
              >
                <li className="px-4 py-2 hover:bg-blue-100">
                  <Link to="/products?category=1" className="text-18 text-gray-700">
                    Subitem 1
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-blue-100">
                  <Link to="/products?category=2" className="text-18 text-gray-700">
                    Subitem 2
                  </Link>
                </li>
                <li className="px-4 py-2 hover:bg-blue-100">
                  <Link to="/products?category=3" className="text-18 text-gray-700">
                    Subitem 3
                  </Link>
                </li>
              </ul>
            </li> */}
            <li className={`relative group ${isActive('/contact') ? 'border-b-2 border-primary' : ''}`}>
              <Link
                to="/contact"
                className="text-18 text-gray-700 hover:text-primary transition duration-300"
                onClick={() => setIsDropdownOpen(false)}
              >
                Support
              </Link>
            </li>
          </ul>
          <div className="hidden lg:flex">
            <PhoneIcon className="size-6 text-blue-500" />
            <span className='ms-1'>0120 302-203</span>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <ul className="lg:hidden bg-white shadow-md rounded-md py-4 space-y-2">
            <li>
              <Link
                to="/"
                className={`block px-4 py-2 text-18 text-gray-700 hover:bg-blue-100 ${
                  isActive('/') ? 'bg-blue-100' : ''
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`block px-4 py-2 text-18 text-gray-700 hover:bg-blue-100 ${
                  isActive('/about') ? 'bg-blue-100' : ''
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                About
              </Link>
            </li>
            <li>
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="block px-4 py-2 w-full text-left text-18 text-gray-700 hover:bg-blue-100"
              >
                Products
              </button>
              {isDropdownOpen && (
                <ul className="pl-4">
                  <li className="px-4 py-2 hover:bg-blue-100">
                    <Link to="/products?category=1" className="text-18 text-gray-700">
                      Subitem 1
                    </Link>
                  </li>
                  <li className="px-4 py-2 hover:bg-blue-100">
                    <Link to="/products?category=2" className="text-18 text-gray-700">
                      Subitem 2
                    </Link>
                  </li>
                  <li className="px-4 py-2 hover:bg-blue-100">
                    <Link to="/products?category=3" className="text-18 text-gray-700">
                      Subitem 3
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link
                to="/contact"
                className={`block px-4 py-2 text-18 text-gray-700 hover:bg-blue-100 ${
                  isActive('/contact') ? 'bg-blue-100' : ''
                }`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Support
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;