import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import p1 from '../../assets/images/pp1.webp';
import p2 from '../../assets/images/pp2.webp';
import p3 from '../../assets/images/pp3.webp';


const Products = () => {
  /* const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch products from the backend
    axios.get('/api/products')
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, []); */

  const categories = [
    {
      id: 1,
      name: "All",
      subcategories: [],
    },
    {
      id: 2,
      name: "Abrasives",
      subcategories: ["Abrasives Belts", "Abrasives Brushes", "Abrasives Discs", "Abrasives Sheets & Rolls", "Abrasives Wheels", "Sanding Screen"],
    }    
  ];

  const industries = [
    {id: 1, name: "Automotive"},
    {id: 2, name: "Commercial Solutions"},
    {id: 3, name: "Consumer"},
    {id: 4, name: "Design & Construction"},
    {id: 5, name: "Electronics"},
    {id: 6, name: "Energy"}
  ];

  const brands = [
    {id: 1, name: "3M Xtract"},
    {id: 2, name: "Cubitron"},
    {id: 3, name: "Finesse-it"},
    {id: 4, name: "Pro Grade Precision"},
  ];
  
  const products = [
    {
      id: 1,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p1,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 2,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p2,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 3,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p3,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 4,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p2,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 5,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p3,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 6,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p1,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 7,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p3,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 8,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p1,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 9,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p2,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 10,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p2,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 11,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p3,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
    {
      id: 12,
      name: "Scotch Brite Surface Conditioning Low Stretch Roll",
      image: p1,
      details: {
        "3M Stock": "7100227325",        
        "Previous 3M Stock": "60000320014",
        UPC: "00051115198373",
        "Alternative ID": "6022J",
      },
    },
  ];
  

  const [openCategory, setOpenCategory] = useState(null);

  const toggleSubmenu = (id) => {
    setOpenCategory(openCategory === id ? null : id);
  };

  const [visibleProducts, setVisibleProducts] = useState(6); // Show 6 products initially
  
  // Function to load more products
  const loadMoreProducts = () => {
      setVisibleProducts(prev => prev + 6); // Increase visible products by 6 each time
  };
  

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Container */}
      <div className="flex flex-wrap">
        {/* Sidebar */}
        <aside className="w-full lg:w-1/5 border bottom-1 border-E6ECF6 p-6 rounded-md space-y-6">
          {/* Categories */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-3">Category</h2>
            <ul className="space-y-4">
            {categories.map((category) => (
              <li key={category.id}>
                <div
                  className="flex justify-between items-center text-gray-600 hover:text-blue-500 cursor-pointer"
                  onClick={() => toggleSubmenu(category.id)}
                >
                  <span>{category.name}</span>
                  {category.subcategories.length > 0 && (
                    <span className="text-sm">
                      {openCategory === category.id ? "▲" : "▼"}
                    </span>
                  )}
                </div>
                {openCategory === category.id && category.subcategories.length > 0 && (
                  <ul className="mt-2 space-y-2 pl-4">
                    {category.subcategories.map((subcategory, index) => (
                      <li
                        key={index}
                        className="text-gray-500 hover:text-blue-400 cursor-pointer"
                      >
                        {subcategory}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          </div>

          {/* Industry */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800">Industry</h2>
            <ul className="mt-4 space-y-2">
              {industries.map((industry) => (
                <li
                  key={industry.id}
                  className="text-gray-600 hover:text-blue-500 cursor-pointer"
                >
                  {industry.name}
                </li>
              ))}
            </ul>
          </div>

          {/* Brands */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800">Brands</h2>
            <ul className="mt-4 space-y-2">
              {brands.map((brand) => (
                <li
                  key={brand.id}
                  className="text-gray-600 hover:text-blue-500 cursor-pointer"
                >
                  {brand.name}
                </li>
              ))}
            </ul>
          </div>
        </aside>

        {/* Main Content */}
        <main className="w-full lg:w-4/5 p-6 space-y-8">
          {/* Header Row */}
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-800">All Products</h1>
            <span className="text-blue-600">{products.length} Products Available</span>
          </div>

          {/* Filter */}
          <div className="bg-white p-4 rounded-md shadow-md flex flex-wrap gap-4">
            <input
              type="text"
              placeholder="Search by name"
              className="border border-gray-300 p-2 rounded-md w-full md:w-1/3"
            />
            <input
              type="text"
              placeholder="Search by category"
              className="border border-gray-300 p-2 rounded-md w-full md:w-1/3"
            />
            <input
              type="text"
              placeholder="Search by industry"
              className="border border-gray-300 p-2 rounded-md w-full md:w-1/3"
            />
          </div>

          {/* Products Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {products.slice(0, visibleProducts).map((product) => (
              <div key={product.id} className="bg-f7f4ed rounded-sm border bottom-1 border-E6ECF6">
                <Link to={`/product/${product.id}`} className="block">
                  <img src={product.image} alt={product.name} />
                  <h3 className="text-090909 mt-2 mb-3 font-semibold px-2">{product.name}</h3>
                  <ul className="grid grid-cols-2 gap-4 p-2">
                    {Object.entries(product.details).map(([key, value], index) => (
                      <li
                        key={key}
                        className={`${(index === 1 || index === 3) ? 'text-right' : ''}`}
                      >
                        <p className={`text-gray-500 ${(index === 1 || index === 3) ? 'mr-0' : 'mr-2'}`}>{key}</p>
                        <p>{value}</p>
                      </li>
                    ))}
                  </ul>
                </Link>
              </div>
            ))}
          </div>

          {/* Show More Button */}
          {visibleProducts < products.length && (
            <div className="text-center mt-6">
              <button
                onClick={loadMoreProducts}
                className="bg-blue-50 text-blue-600 px-6 py-2 rounded-lg hover:bg-blue-300 transition duration-300 hover:text-gray-600"
              >
                Show More
              </button>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Products;

