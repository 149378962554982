import React from 'react';
import homeBanner from '../../assets/images/home-banner.webp';
import circularImg from '../../assets/images/circleimg.webp';
import aboutUs from '../../assets/images/about-bg.webp';
import p1 from '../../assets/images/1.webp';
import p2 from '../../assets/images/2.webp';
import p3 from '../../assets/images/3.webp';
import p4 from '../../assets/images/4.webp';
import p5 from '../../assets/images/5.webp';
import clientsImg from '../../assets/images/clientsimg.webp';
import clientsThum from '../../assets/images/clientThum.webp';
import t1 from '../../assets/images/t1.webp';
import t2 from '../../assets/images/t2.webp';
import t3 from '../../assets/images/t3.webp';
import { StarIcon, PlayIcon, ClockIcon } from '@heroicons/react/24/solid'


const Home = () => {
  const products = [
    {
      name: "Self Adhesive Tape",
      imageUrl: p1,
    },
    {
      name: "Cotton Tape",
      imageUrl: p2,
    },
    {
      name: "Wonder Tape",
      imageUrl: p3,
    },
    {
      name: "Foam Tape",
      imageUrl: p4,
    },
    {
      name: "Polyester Tape",
      imageUrl: p5,
    },
  ];

  const columnsData = [
    { id: 1, heading: "Faster Assembly Time", content: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." },
    { id: 2, heading: "Design Flexibility", content: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." },
    { id: 3, heading: "Design Flexibility", content: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." },
    { id: 4, heading: "Design Flexibility", content: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." },
    { id: 5, heading: "Design Flexibility", content: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." },
  ];

  return (
    <>
    <section
      className="bg-cover bg-center h-screen flex items-center max-h-[667px]"
      style={{ backgroundImage: `url(${homeBanner})` }}
    >
      <div className="container mx-auto flex flex-wrap lg:flex-nowrap items-center">
        {/* Left Content */}
        <div className="w-full lg:w-1/2 text-white space-y-6 p-6 mb-3">
          <h1 className="text-090909 text-4xl font-bold leading-tight">Double Sided Tapes</h1>
          <p className="text-090909 text-lg leading-relaxed">
          Whether you’re bonding similar or completely different substrates, our double-sided tapes provide the strength, conformability, adhesion and aesthetic requirements you need to make your project successful.
          </p>
          <button className="bg-090909 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition duration-300">
          Ask a 3M Expert
          </button>
          <div className="flex items-center space-x-4">
            <img src={circularImg} alt="" />
            <div className="text-090909 flex items-center space-x-4">
              <span className="block">14k Positive Review</span>
              <span className="block text-4xl">99%</span>
              <span className="block">Satisfaction Work Rate</span>
            </div>
          </div>
        </div>   
        {/* Right Image */}
        <div className="w-full lg:w-1/2 flex justify-center p-6"></div>
      </div>
    </section>
    <section className="bg-gray-100 py-8">
      <div className='container mx-auto'>
        {/* First Row: Centered Heading */}
        <div className="flex flex-col items-center justify-center text-center mb-8">
          <h1 className="text-4xl font-bold text-090909 mb-4">
            About Double Sided Tape
          </h1>
          <p className="text-lg text-090909 w-full">
          A double sided tape has pressure-sensitive adhesive exposed on both sides, allowing two parts to be bonded together by the tape between them. A carrier that holds adhesive can range from a film as thin as a fraction of a millimeter up to a thick foam that helps damp vibrations. Similarly, adhesives can meet specific needs from low-tack that allows for repositioning all the way up to a permanent bonding solutions. A double sided tape that has a carrier can be produced with the same adhesive on both sides, or with different adhesives to meet the bonding requirements of different substrates. Our adhesive experts are happy to work with you to determine which of our thousands of tapes best meets your needs....
          </p>
        </div>

        {/* Second Row: Image */}
        <div className="relative flex justify-center items-center max-w-6xl mx-auto">
            {/* Image */}
            <img 
              src={aboutUs} 
              alt="About Us" 
              className="rounded-lg shadow-lg max-w-full"
            />

            {/* Button */}
            <button 
              className="absolute bg-6b72804f opacity-90 p-4 rounded-full shadow-lg flex items-center justify-center group"
              style={{ transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
            >
              <PlayIcon className="text-white w-5 h-5 group-hover:text-white" />
            </button>
        </div>
      </div>  
    </section>
    <section className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* First Column - Content */}
          <div className="p-6 rounded-lg flex flex-col justify-between">
            <div>
              <h2 className="text-2xl font-bold mb-4 text-090909">Listing of Double Sided Tape</h2>
              <p className="text-090909 mb-6">
              In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document
              </p>
            </div>
            <div className='flex items-center justify-center'>
              <a href="/products" className="mt-auto bg-090909 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition max-w-[120px]">
                View All
              </a>
            </div>
          </div>

          {/* Product Columns */}
          {products.map((product, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center"
            >
              <img
                src={product.imageUrl}
                alt={product.imageUrl}
                className="w-full h-auto rounded-lg"
              />
              <span className="absolute top-2 left-2 text-sm font-bold text-090909 px-2 py-1">
                {product.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="max-w-7xl mx-auto p-6 flex flex-col lg:flex-row gap-6">
      {/* First Column (30%) */}
      <div className="w-full lg:w-3/10 flex items-center justify-center">
        <img
          src={clientsImg}
          alt=""
          className="object-cover w-full h-auto max-w-[300px]"
        />
      </div>

      {/* Second Column (70%) */}
      <div className="w-full lg:w-7/10 flex flex-col justify-start space-y-4">
        <div>
          <h2 className="text-2xl font-bold text-00">
            What Says Our Clients About Genwin
          </h2>
          <p className="text-090909 mt-2">
          A double sided tape has pressure-sensitive adhesive exposed on both sides, allowing two parts to be bonded together by the tape between them. A carrier that holds adhesive can range from a film as thin as a fraction of a millimeter up to a thick foam that helps damp vibrations. Similarly, adhesives can meet specific needs from low-tack that allows for repositioning all the way up to a permanent bonding solutions. A double sided tape that has a carrier can be produced with the same adhesive on both sides, or with different adhesives to meet the bonding requirements of different substrates. Our adhesive experts are happy to work with you to determine which of our thousands of tapes best meets your needs.
          </p>
        </div>
        {/* Small Image in the second row */}
        <div className="space-y-2">
          {/* Image */}
          <div className='flex items-center justify-start space-x-2'>
            <img
              src={clientsThum}
              alt="Small Image"
              className="rounded-md shadow-sm object-cover"
            />
            <div className=''>
              <h4 className='text-090909 font-semibold'>Swati Srivastava</h4>
              <p className='text-gray-400'>Designer</p>
            </div>  
          </div>
          {/* Stars */}
          <div className="flex space-x-1">
            {[...Array(5)].map((_, index) => (
              <StarIcon key={index} className="text-yellow-500 w-5 h-5" />
            ))}
          </div>
        </div>
      </div>
    </section>
    <section className="max-w-6xl mx-auto px-6 py-12 grid grid-cols-1 md:grid-cols-3 gap-6">
      {/* First Column */}
      <div className="p-6 rounded-lg">
        <h2 className="text-2xl font-bold text-090909">Benefits of Double Sided Tape</h2>
        <p className="text-gray-700 mt-4">
        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.
        </p>
      </div>

      {/* Other Columns */}
      {columnsData.map((col) => (
        <div
          key={col.id}
          className="group bg-white p-6 rounded-lg hover:rotate-[-6deg] hover:bg-primary transition-transform duration-500"
        >
          <div className="flex items-center space-x-2">
            <ClockIcon className="text-0090909 w-5 h-5 group-hover:text-white" />
            <h3 className="text-xl font-semibold text-0090909 group-hover:text-white">
              {col.heading}
            </h3>
          </div>
          <p className="text-0090909 mt-4 group-hover:text-white">
            {col.content}
          </p>
          <a
            href="#read-more"
            className="text-gray-400 mt-4 inline-block hover:underline group-hover:text-white transition-colors duration-500"
          >
            Read More
          </a>
        </div>
      ))}
    </section>
    <section className="bg-gray-100 py-16">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-gray-800">Customer Testimonial & Videos</h2>
      </div>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
        {/* Column 1 */}
        <div className="relative">
          <img
            src={t1}
            alt="Image 1"
            className="w-full h-64 object-cover rounded-lg"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <button className="bg-6b72804f opacity-90 p-4 rounded-full shadow-lg">
            <PlayIcon className="text-white w-5 h-5 group-hover:text-white" />
            </button>
          </div>
          <h3 className="mt-4 text-xl font-semibold text-center text-gray-800">Nebia Customer Testimonial</h3>
          <p className="mt-2 text-gray-600">
          In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content
          </p>
        </div>

        {/* Column 2 */}
        <div className="relative">
          <img
            src={t2}
            alt="Image 2"
            className="w-full h-64 object-cover rounded-lg"
          />
          <div className="absolute inset-0 flex items-center justify-center">
          <button className="bg-6b72804f opacity-90 p-4 rounded-full shadow-lg">
            <PlayIcon className="text-white w-5 h-5 group-hover:text-white" />
            </button>
          </div>
          <h3 className="mt-4 text-xl font-semibold text-center text-gray-800">Nebia Customer Testimonial</h3>
          <p className="mt-2 text-gray-600">
          In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content
          </p>
        </div>

        {/* Column 3 */}
        <div className="relative">
          <img
            src={t3}
            alt="Image 3"
            className="w-full h-64 object-cover rounded-lg"
          />
          <div className="absolute inset-0 flex items-center justify-center">
          <button className="bg-6b72804f opacity-90 p-4 rounded-full shadow-lg">
            <PlayIcon className="text-white w-5 h-5 group-hover:text-white" />
            </button>
          </div>
          <h3 className="mt-4 text-xl font-semibold text-center text-gray-800">Nebia Customer Testimonial</h3>
          <p className="mt-2 text-gray-600">
          In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content
          </p>
        </div>
      </div>
    </section>
    </>
  );
};

export default Home;
