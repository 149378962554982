import React from 'react';
import aboutBanner from '../../assets/images/aboutBanner.webp';
import aboutUs from '../../assets/images/about-bg.webp';
import aboutDesL from '../../assets/images/ad1.webp';
import aboutDesR from '../../assets/images/ad2.webp';
import t1 from '../../assets/images/t1.webp';
import t2 from '../../assets/images/t2.webp';
import t3 from '../../assets/images/t3.webp';
import op1 from '../../assets/images/op1.webp';
import op2 from '../../assets/images/op2.webp';
import op3 from '../../assets/images/op1.webp';
import op4 from '../../assets/images/op2.webp';
import { StarIcon, PlayIcon, ClockIcon } from '@heroicons/react/24/solid'

const AboutUs = () => {
  const Testimonials =[
    {
      name:'Nebia Customer Testimonial',
      desc: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content',
      imagePath: t1,
    },
    {
      name:'Nebia Customer Testimonial',
      desc: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content',
      imagePath: t2,
    },
    {
      name:'Nebia Customer Testimonial',
      desc: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content',
      imagePath: t3,
    },
  ];

  const partners = [
    {
      name:'Abc',
      imagePath: op1,
    },
    {
      name:'Abc',
      imagePath: op2,
    },
    {
      name:'Abc',
      imagePath: op3,
    },
    {
      name:'Abc',
      imagePath: op4,
    }
  ]
  return (
    <div className="space-y-16">

      {/* First Section - Banner */}
      <section
        className="bg-cover bg-center h-[400px] flex items-center justify-center text-center"
        style={{ backgroundImage: `url(${aboutBanner})` }} // Replace with your image URL
      >
        <div className="bg-opacity-50 p-6 rounded-md">
          <h1 className="text-4xl font-bold text-white">About Us</h1>
          <p className="max-w-2xl text-white">
            Explore our services, customer testimonials, and our trusted partners.
          </p>
        </div>
      </section>
      {/* <section className="relative">
        <img
          src={aboutBanner}
          alt="Banner"
          className="w-full h-[500px] object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center bg-black bg-opacity-50 text-white">
          <h1 className="text-4xl font-bold mb-4">About Us</h1>
          <p className="max-w-2xl">
            Explore our services, customer testimonials, and our trusted partners.
          </p>
        </div>
      </section> */}

      {/* Second Section - First Intro */}
      <section className="bg-gray-100 py-8">
        <div className='container mx-auto'>
          {/* First Row: Centered Heading */}
          <div className="flex flex-col items-center justify-center text-center mb-8">
            <h1 className="text-4xl font-bold text-090909 mb-4">
              About Double Sided Tape
            </h1>
            <p className="text-lg text-090909 w-full">
            A double sided tape has pressure-sensitive adhesive exposed on both sides, allowing two parts to be bonded together by the tape between them. A carrier that holds adhesive can range from a film as thin as a fraction of a millimeter up to a thick foam that helps damp vibrations. Similarly, adhesives can meet specific needs from low-tack that allows for repositioning all the way up to a permanent bonding solutions. A double sided tape that has a carrier can be produced with the same adhesive on both sides, or with different adhesives to meet the bonding requirements of different substrates. Our adhesive experts are happy to work with you to determine which of our thousands of tapes best meets your needs....
            </p>
          </div>

          {/* Second Row: Image */}
          <div className="relative flex justify-center items-center max-w-6xl mx-auto">
            {/* Image */}
            <img 
              src={aboutUs} 
              alt="About Us" 
              className="rounded-lg shadow-lg max-w-full"
            />

            {/* Button */}
            <button 
              className="absolute bg-6b72804f opacity-90 p-4 rounded-full shadow-lg flex items-center justify-center group"
              style={{ transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
            >
              <PlayIcon className="text-white w-5 h-5 group-hover:text-white" />
            </button>
          </div>
        </div>  
      </section>      

      {/* Third Section - Second Intro */}
      <section className="container mx-auto px-4 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
        
        {/* Left Column */}
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-4">
            <img
              src={aboutDesL}
              alt="Top Image"
              className="w-full rounded-md shadow-lg"
            />
            <div className="flex justify-center items-center">
              <span className="text-yellow-500 text-2xl">&#9733; &#9733; &#9733; &#9733; &#9734;</span>
            </div>
          </div>
          <div className="space-y-4">
            <div className="bg-gray-100 p-4 rounded-md shadow-md">
              <p>
                Here is some content about the image on the left and related
                information.
              </p>
            </div>
            <img
              src={aboutDesR}
              alt="Bottom Image"
              className="w-full rounded-md shadow-lg"
            />
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-4">
          <h2 className="text-2xl font-bold">A Little Bit More About Our Products</h2>
          <p>
          A double sided tape has pressure-sensitive adhesive exposed on both sides, allowing two parts to be bonded together by the tape between them. A carrier that holds adhesive can range from a film as thin as a fraction of a millimeter up to a thick foam that helps damp vibrations. Similarly, adhesives can meet specific needs from low-tack that allows for repositioning all the way up to a permanent bonding solutions. A double sided tape that has a carrier can be produced with the same adhesive on both sides, or with different adhesives to meet the bonding requirements of different substrates. Our adhesive experts are happy to work with you to determine which of our thousands of tapes best meets your needs.
          </p>
        </div>
      </section>
      

      {/* Fourth Section - Customer Testimonials */}
      <section className="bg-gray-50 py-8">
        <div className="container mx-auto px-4 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-8">Customer Testimonial & Videos</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {Testimonials.map(
              (testimonial, index) => (
                <div key={index} className="relative">
                <img
                  src={testimonial.imagePath}
                  alt="Image 3"
                  className="w-full h-64 object-cover rounded-lg"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                <button className="bg-6b72804f opacity-90 p-4 rounded-full shadow-lg">
                  <PlayIcon className="text-white w-5 h-5 group-hover:text-white" />
                  </button>
                </div>
                <h3 className="mt-4 text-xl font-semibold text-start text-gray-800">{testimonial.name}</h3>
                <p className="mt-2 text-gray-600 text-start">
                {testimonial.desc}
                </p>
              </div>
              )
            )}
          </div>
        </div>
      </section>

      {/* Fifth Section - Our Partners */}
      <section className="bg-white py-8">
        <div className="container mx-auto px-4 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-8">Our Partners</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {partners.map(
              (partner, index) => (
                <div
                  key={index}
                  className="p-4 rounded-md"
                >
                  <img
                    src={partner.imagePath}
                    alt={partner.name}
                    className="w-full rounded-md mt-4"
                  />
                  <span className='hidden'>{partner.name}</span>
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
