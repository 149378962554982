import { useState } from 'react';
import { useParams } from 'react-router-dom';
import p1 from '../../assets/images/pp1.webp';
import p2 from '../../assets/images/pp2.webp';
import p3 from '../../assets/images/pp3.webp';
import { PhoneIcon } from '@heroicons/react/24/solid'

const Details = ({ prod, sProducts }) => {
    const [visibleSimilarProducts, setVisibleSimilarProducts] = useState(4); // Show 4 similar products initially
    const products = [
        {
          id: 1,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p1,
          price: '100',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 2,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p2,
          price: '150',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 3,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p3,
          price: '200',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 4,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p2,
          price: '250',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 5,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p3,
          price: '175',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 6,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p1,
          price: '100',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 7,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p3,
          price: '300',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 8,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p1,
          price: '275',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 9,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p2,
          price: '225',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 10,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p2,
          price: '333',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 11,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p3,
          price: '500',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
        {
          id: 12,
          name: "Scotch Brite Surface Conditioning Low Stretch Roll",
          image: p1,
          price: '325',
          thumbnails: [p1,p2,p3],
          shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
          shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
          strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
          details: {
            "3M Stock": "7100227325",        
            "Previous 3M Stock": "60000320014",
            UPC: "00051115198373",
            "Alternative ID": "6022J",
          },
          sellerName:'Denny',
          sellerMobile:'9876543210',
          sellerAdd:'D-124, NY, USA',
          sellerURL:'genwin.com',
        },
    ];

    const similarProducts = [
        {
            id: 1,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p1,
            price: '100',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 2,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p2,
            price: '150',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 3,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p3,
            price: '200',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 4,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p2,
            price: '250',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 5,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p3,
            price: '175',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 6,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p1,
            price: '100',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 7,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p3,
            price: '300',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 8,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p1,
            price: '275',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 9,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p2,
            price: '225',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 10,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p2,
            price: '333',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 11,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p3,
            price: '500',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
          {
            id: 12,
            name: "Scotch Brite Surface Conditioning Low Stretch Roll",
            image: p1,
            price: '325',
            thumbnails: [p1,p2,p3],
            shortDetails: {'Tape Length' : '20m', 'Tape Width' : '1 Inch', 'Backing Material' : 'Polyester', 'Tape Type' : 'Non-Adhesive'},
            shortContent: 'Genwin Polyester Double-Sided Tape: Strong, Reliable Bonding for Various Applications Indulge in a versatile and powerful adhesive solution with Genwin Polyester Double-Sided Tape. Unwavering strength',
            strength: {'High bond strength': 'Provides a secure hold for a wide range of materials, including paper, plastic, wood'},
            details: {
              "3M Stock": "7100227325",        
              "Previous 3M Stock": "60000320014",
              UPC: "00051115198373",
              "Alternative ID": "6022J",
            },
            sellerName:'Denny',
            sellerMobile:'9876543210',
            sellerAdd:'D-124, NY, USA',
            sellerURL:'genwin.com',
          },
    ];
    
    const { id } = useParams(); // Get the product id from the URL
    const product = products.find(p => p.id === parseInt(id)); // Find the product with the matching id
    const [mainImage, setMainImage] = useState(product.image);
    const handleThumbnailClick = (thumb) => {
        setMainImage(thumb); // Update the main image
    };

    if (!product) {
    return <p>Product not found.</p>;
    }
    

    const loadMoreSimilarProducts = () => {
        setVisibleSimilarProducts(prev => prev + 4); // Increase visible similar products by 4 each time
    };

  return (
    <main className="w-full p-6 space-y-8">
        <div className='container mx-auto'>
      {/* First Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* First Column: Product Image with Thumbnails */}
        <div className="flex flex-col items-center">
            {/* Main Image */}
            <img
                src={mainImage}
                alt={product.name}
                className="w-full max-w-md rounded-lg"
            />

            {/* Thumbnails */}
            <div className="flex space-x-2 mt-4">
                {product.thumbnails.map((thumb, idx) => (
                <img
                    key={idx}
                    src={thumb}
                    alt={`Thumbnail ${idx + 1}`}
                    className="w-16 h-16 object-cover rounded-lg cursor-pointer hover:ring-2 hover:ring-blue-500"
                    onClick={() => handleThumbnailClick(thumb)} // Handle click event
                />
                ))}
            </div>
        </div>

        {/* Second Column: Product Details */}
        <div>
          <h1 className="text-3xl font-semibold text-gray-800">{product.name}</h1>
          <p className="text-xl font-bold text-blue-600 mt-2">${product.price}</p>          
            <table className="table-auto border-collapse w-full">
                <tbody>
                    {Object.entries(product.shortDetails).map(([key, value], index) => (
                    <tr key={key} className="border-b">
                        <td className="bg-gray-100 p-2 font-medium text-gray-700">{key}</td>
                        <td className="p-2 text-gray-600">{value}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
            <p className="text-gray-500 mt-4">
            {Object.entries(product.strength).map(([key, value], index) => (
                <span key={index} className="block">
                <span className="text-gray-700 font-semibold">{key}: </span>
                <span>{value}</span>
                </span>
            ))}
            </p>
          <div className="mt-4">
            <a href="#view-more" className="text-blue-600 hover:underline">
              View More Details
            </a>
          </div>
          <button className="mt-4 bg-090909 text-white px-6 py-2 rounded-md hover:bg-blue-700">            
            <p>Get Latest Price</p>
            <p className='text-12'>Request a Quote</p>
          </button>
        </div>
      </div>

      {/* Second Section: Similar Products */}
      <div className='py-5'>
        <h2 className="text-2xl font-semibold text-gray-800">Similar Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
          {similarProducts.slice(0, visibleSimilarProducts).map((prod) => (
            <div key={prod.id} className="bg-f7f4ed rounded-sm border bottom-1 border-E6ECF6 p-4">
              <img src={prod.image} alt={prod.name} className="w-full h-40 object-cover rounded-md" />
              <h3 className="text-xl font-semibold mt-2">{prod.name}</h3>
              <p className="text-gray-500 mt-1">${prod.price}</p>
              <div className="mt-4">
                <div className="flex">
                    <button className="w-full bg-blue-100 border-b border-blue-500 text-white px-4 py-2 mb-3 rounded-md hover:bg-blue-700 flex items-center space-x-2">
                        <PhoneIcon className="w-6 h-6 text-090909" />
                        <span className="text-blue-500 font-semibold">View Mobile Number</span>
                    </button>
                </div>
                <button className="w-full bg-090909 text-white px-4 py-2 mb-3 rounded-md hover:bg-green-700">
                  Get Latest Price
                </button>
              </div>
            </div>
          ))}
        </div>
        {visibleSimilarProducts < similarProducts.length && (
          <div className="text-center mt-6">
            <button
              onClick={loadMoreSimilarProducts}
              className="bg-blue-50 text-blue-600 px-6 py-2 rounded-lg hover:bg-blue-300 transition duration-300 hover:text-gray-600"
            >
              Show More
            </button>
          </div>
        )}
      </div>

      {/* Third Section: Tabs and Enquiry Form */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column: Tabs */}
        <div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
            <h3 className="text-xl font-semibold text-gray-800">Company Details</h3>
            <p className="text-gray-500 mt-2">Company information goes here...</p>
          </div>
          <div className="bg-gray-100 p-4 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800">Product Gallery</h3>
            <div className="mt-2">
              {/* {product.gallery.map((image, idx) => (
                <img key={idx} src={image} alt={`Product Gallery ${idx + 1}`} className="w-24 h-24 object-cover rounded-md mr-2" />
              ))} */}
            </div>
          </div>
        </div>

        {/* Right Column: Enquiry Form */}
        <div>
          <h3 className="text-xl font-semibold text-gray-800">Enquiry Form</h3>
          <form className="space-y-4 mt-4">
            <input type="text" placeholder="Name" className="border p-2 w-full rounded-md" />
            <input type="email" placeholder="Email" className="border p-2 w-full rounded-md" />
            <textarea placeholder="Message" className="border p-2 w-full rounded-md" rows="4"></textarea>
            <button type="submit" className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700">
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* Fourth Section: User Reviews and Seller Contact Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column: User Reviews */}
        <div>
          <h3 className="text-2xl font-semibold text-gray-800">User Reviews</h3>
          <div className="mt-4 space-y-4">
            {/* {product.reviews.map((review, idx) => (
              <div key={idx} className="bg-gray-100 p-4 rounded-md shadow-md">
                <p className="text-lg font-semibold">{review.username}</p>
                <p className="text-gray-500 mt-2">{review.text}</p>
              </div>
            ))} */}
          </div>
        </div>

        {/* Right Column: Seller Contact Details */}
        <div>
          <h3 className="text-xl font-semibold text-gray-800">Seller Contact</h3>
          <div className="mt-4">
            {/* <p className="text-gray-500">Company Name: {product.seller.companyName}</p>
            <p className="text-gray-500">Seller Name: {product.seller.name}</p>
            <p className="text-gray-500">Address: {product.seller.address}</p>
            <p className="text-gray-500">Website: <a href={product.seller.website} className="text-blue-600 hover:underline">{product.seller.website}</a></p>
            <p className="text-gray-500">Mobile Number: {product.seller.phone}</p> */}
            <div className="space-x-4 mt-4">
              <button className="bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700">Send SMS</button>
              <button className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700">Send Email</button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </main>
  );
};

export default Details;
