import React, { useState } from 'react';
import contactBanner from '../../assets/images/contactBanner.webp'

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add form submission logic here
  };

  return (
    <div>
      {/* Section 1: Banner */}
      <section
        className="bg-cover bg-center h-[400px] flex items-center justify-center text-center"
        style={{ backgroundImage: `url(${contactBanner})` }} // Replace with your image URL
      >
        <div className="bg-opacity-50 p-6 rounded-md">
          <h1 className="text-4xl font-bold text-white">Contact Us</h1>
          <p className="mt-4 text-gray-200">
            We're here to help! Feel free to reach out to us for any inquiries.
          </p>
        </div>
      </section>

      {/* Section 2: Content and Form */}
      <section className="py-16 bg-gray-100">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
          {/* Content Column */}
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-gray-800">Have a Question or Feedback?</h2>
            <p className="text-gray-600">
              If you have any questions, comments, or just want to say hello,
              please feel free to use the contact form below to reach us.
            </p>
          </div>

          {/* Form Column */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="border border-gray-300 rounded-md p-3 w-full"
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  className="border border-gray-300 rounded-md p-3 w-full"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  className="border border-gray-300 rounded-md p-3 w-full"
                />
                <input
                  type="text"
                  placeholder="Subject"
                  className="border border-gray-300 rounded-md p-3 w-full"
                />
              </div>
              <textarea
                rows="4"
                placeholder="Your Message"
                className="border border-gray-300 rounded-md p-3 w-full"
              ></textarea>
              <button
                type="submit"
                className="bg-090909 text-white px-6 py-3 rounded-md hover:bg-primary transition"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>

      {/* Section 3: Map and Address */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
          {/* Google Map */}
          <div className="rounded-lg overflow-hidden shadow-md">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=..."
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>

          {/* Address */}
          <div className="space-y-6">
            <h3 className="text-2xl font-bold text-gray-800">Our Address</h3>
            <p className="text-gray-600">
              <strong>Office:</strong> 123 Main Street, City, Country, 12345
            </p>
            <p className="text-gray-600">
              <strong>Phone:</strong> +1 (555) 123-4567
            </p>
            <p className="text-gray-600">
              <strong>Email:</strong> contact@genwin.com
            </p>
          </div>
        </div>
      </section>

      {/* Section 4: Social Media */}
      <section className="py-8 bg-gray-100 text-center text-090909">
        <h3 className="text-2xl font-bold mb-4">Follow our official social network</h3>
        <div className="flex justify-center space-x-6">
          <a href="https://facebook.com" className="bg-blue-100 p-2 rounded-sm text-primary hover:text-blue-300">
              <span class="[&>svg]:h-5 [&>svg]:w-5">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 320 512">
                  <path
                  d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
              </svg>
              </span>
          </a>
          <a href="https://twitter.com" className="bg-blue-100 p-2 rounded-sm text-primary hover:text-blue-300">
              <span class="[&>svg]:h-5 [&>svg]:w-5">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 512 512">
                      <path
                      d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                  </svg>
              </span>
          </a>    
          <a href="https://twitter.com" className="bg-blue-100 p-2 rounded-sm text-primary hover:text-blue-300">
              <span class="[&>svg]:h-5 [&>svg]:w-5">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 448 512">
                      <path
                      d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                  </svg>
              </span>
          </a>
          <a href="https://instagram.com" className="bg-blue-100 p-2 rounded-sm text-primary hover:text-blue-300">
              <span class="[&>svg]:h-5 [&>svg]:w-5">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 448 512">
                  <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
              </span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
