import React from 'react';

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <h1>About Us</h1>
      <p>
        We are a company dedicated to providing the best products and services to our customers.
        Our mission is to deliver quality and satisfaction at every step.
      </p>
    </div>
  );
};

export default AdminDashboard;
